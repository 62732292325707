import * as React from "react";
import { css, withTheme } from "@delight-js/react";

import PageHeader from "../components/PageHeader";
import Button from "../components/Button";
import Grid from "../components/layout/Grid";
import Row from "../components/layout/Row";
import Cell from "../components/layout/Cell";
import { Link } from "gatsby";

const NotFoundPage = withTheme(({ theme }) => {
  return (
    <React.Fragment>
      <PageHeader
        overline="Oh no! What happend to the cookie dough?"
        headline="404, nothing found here!"
        subline={["Blubb", "Blubb", "Blubb"].map((item, index, { length }) => (
          <React.Fragment key={index}>
            {item.replace(/ /g, "\u00a0") +
              (length - 1 - index > 0 ? "\u00a0| " : "")}
          </React.Fragment>
        ))}
      />
      <Grid>
        <Row>
          <Cell
            span={{ default: 10 / 12, lg: 8 / 12 }}
            offset={{ default: 1 / 12, lg: 2 / 12 }}
          >
            <img
              src="/images/error-fish.svg"
              alt=""
              css={css(`
        display: block;
        width: 100%;
        height: auto;
      `)}
            />
          </Cell>
        </Row>
      </Grid>
      <div css={css`$mb26; text-align: center;`(theme)}>
        <Button as={Link} to="/">
          Return to tasty homepage
        </Button>
      </div>{" "}
    </React.Fragment>
  );
});

export default NotFoundPage;
